/* eslint-disable react/no-unescaped-entities */
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/outline";
import { useApi } from "components/hooks/useApi";
import moment from "moment";
import { useEffect, useState } from "react";
import { classNames } from "util/common";
export default function Slider({}) {
	const [slides, setSlides] = useState([]);
	const api = useApi();
	const getMessages = async () => {
		const res = await api.getSliderMessages();
		if (res.ok) {
			if (res.data?.messages?.length) {
				setSlides(res.data.messages.sort((a, b) => a.order - b.order));
			}
		}
	};
	useEffect(() => {
		getMessages();
	}, []);
	let [current, setCurrent] = useState(0);
	let previousSlide = () => {
		if (current === 0) setCurrent(slides.length - 1);
		else setCurrent(current - 1);
	};
	let nextSlide = () => {
		if (current === slides.length - 1) setCurrent(0);
		else setCurrent(current + 1);
	};
	if (!slides.length) {
		return null;
	}

	return (
		<>
			<div className="overflow-hidden relative lg:flex hidden max-w-[1440px] mx-auto py-1.5 mt-6">
				<div
					className={`flex transition ease-out duration-700 w-full`}
					style={{
						transform: `translateX(${current * 100}%)`,
					}}
				>
					{slides.map(slide => (
						<div
							key={slide.id}
							className="min-w-full flex justify-center mx-auto "
						>
							<div className="w-[90%] mx-auto py-1 bg-white shadow-md rounded-lg overflow-hidden">
								<div className="text-center lg:px-4 font-semibold text-base text-black flex justify-center h-full items-center py-1 flex-col lg:flex-row relative">
									<div className="absolute top-0 right-[3%]">
										<div className="ml-2">
											{moment(slide.created_at).format("DD/MM/YYYY")}
										</div>
									</div>
									<div className=" absolute top-0 left-[3%]">
										<div>
											{slide.order}/{slides.length}
										</div>
									</div>
									{slide?.content ? (
										<div
											className="w-[75%] md:w-[70%] text-center break-words mt-4"
											dangerouslySetInnerHTML={{ __html: slide.content }}
										/>
									) : (
										""
									)}
								</div>
							</div>
						</div>
					))}
				</div>

				{slides.length > 1 && (
					<div className="absolute right-[3%] top-[50%] -translate-y-1/2 flex text-white text-3xl pointer-events-none">
						<button
							className="bg-white rounded-full border-2 p-2 pointer-events-auto"
							onClick={previousSlide}
						>
							<ChevronRightIcon className="w-5 h-5 text-jane-500" />
						</button>
					</div>
				)}
				{slides.length > 1 && (
					<div className="absolute  left-[3%] top-[50%] -translate-y-1/2 flex text-white text-3xl pointer-events-none">
						<button
							className="bg-white rounded-full border-2 p-2 pointer-events-auto"
							onClick={nextSlide}
						>
							<ChevronLeftIcon className="w-5 h-5 text-jane-500" />
						</button>
					</div>
				)}
			</div>
			<div className="flex lg:hidden w-full mt-8">
				<div className={`w-full flex overflow-x-scroll gap-3 min-h-[40px] no-scrollbar ${slides?.length === 1 && "justify-center"} snap-x snap-mandatory`}>
					{slides.map(slide => {
						return (
							<div
								key={slide.id}
								className={classNames("rounded-lg shadow-md bg-white text-black min-w-[90%] min-h-[40px] py-1 flex justify-center items-center mx-1 text-center mb-2 my-1 px-4 font-semibold text-base snap-center",
									slides.length === 1 && "min-w-[98%] sm:min-w-[98%]",
								)}
							>
								<div className="text-center w-full lg:px-4 font-semibold text-base text-black flex justify-center h-full items-center py-1 flex-col lg:flex-row relative overflow-hidden break-words">
									<div className="w-full lg:max-auto flex justify-between absolute top-0">
										<div className="ml-2">
											{moment(slide.created_at).format("DD/MM/YYYY")}
										</div>
										<div>
											{slide.order}/{slides.length}
										</div>
									</div>
									{slide?.content ? (
										<div
											className="w-[80%] text-center break-words mt-4"
											dangerouslySetInnerHTML={{ __html: slide.content }}
										/>
									) : (
										""
									)}
								</div>
							</div>
						);
					})}
				</div>
			</div>
		</>
	);
}
