import AddToCartButton from "components/cart/AddToCartButton";
import React from "react";
import LinkedContent from "./LinkedContent";
import { Button } from "components/form/Button";
import { ChevronLeftIcon, HeartIcon } from "@heroicons/react/outline";
import { ProductTypeUI, VARIANT } from "components/products/ProductTypeUI";
import { listProductTypes } from "util/types";
import HiddenRowProductName from "./HiddenRowProductName";
import {
	formatAmount,
	getPrice,
	getThcCbdRange,
	productAlt,
} from "util/common";
import { useIsInIframe } from "components/hooks/useIsInIframe";
import { isSubdomainRoute } from "pages/_middleware";

const HiddenRowHeader = ({
	row,
	isWidget,
	isSingleStore,
	withCartButton,
	customActionButton,
	tabletMode,
	storeId,
}) => {
	const {
		biz_url: bizUrl,
		catalog_price: catalogPrice,
		batch,
		category,
		eng_name: engName,
		family,
		from_price: fromPrice,
		heb_name: hebName,
		main_img_url: mainImgUrl,
		product_type: productType,
		quantity,
		store_price: storePrice,
		to_price: toPrice,
		id,
		product_id,
		store_product_date: storeProductDateProp,
	} = row;

	const isInIframe = useIsInIframe();
	const isSubdomain = isSubdomainRoute(window.location.hostname);
	const productId = product_id || id;

	const referral = isInIframe
		? "iframe in cannabiz store"
		: isSubdomain
		? "Jane subdomain store"
		: isWidget
		? "Jane widget store"
		: "Jane store";

	let storeProductDate = "";

	if (!!storeProductDateProp) {
		const today = new Date(storeProductDateProp);
		const dd = String(today.getDate()).padStart(2, "0");
		const mm = String(today.getMonth() + 1).padStart(2, "0"); // January is 0!
		const yyyy = today.getFullYear();
		const yy = yyyy.toString().slice(-2);
		storeProductDate = `${mm}/${yy}`;
	}

	const isLowStock = (quantity, lowStockCount, showLowStockQuantity) => {
		if (!quantity || !showLowStockQuantity) return false;
		if (Number(quantity) <= lowStockCount) {
			return true;
		}
	};

	const getPriceSingleStore = (storePrice, catalogPrice) => {
		if (!storePrice && !catalogPrice) {
			return "-";
		}

		if ((catalogPrice === 0 && !storePrice) || (!storePrice && !catalogPrice)) {
			return "לא ידוע";
		}

		if (!storePrice || storePrice === null) {
			return `₪${catalogPrice}`;
		}

		if (!catalogPrice || catalogPrice === null) {
			return `₪${storePrice}`;
		}

		if (storePrice < catalogPrice) {
			return (
				<div className="gap-1.5 flex items-center">
					<span className="text-green-600">₪{storePrice}</span>
					<span className="line-through font-semibold text-sm leading-6">
						₪{catalogPrice}
					</span>
				</div>
			);
		}

		if (storePrice >= catalogPrice) {
			return `₪${storePrice}`;
		}
	};

	return (
		<div className="flex gap-2 w-full">
			<LinkedContent
				isSingleStore={isSingleStore}
				href={`/products/${productId}`}
				bizUrl={bizUrl}
			>
				<img
					src={
						mainImgUrl ||
						(productType === "oil"
							? "/product-placeholder-oil.svg"
							: "/product-placeholder.svg")
					}
					className="w-[80px] h-[80px] sm:w-[165px] sm:h-[165px]"
					alt={productAlt({ heb_name: hebName, eng_name: engName })}
				/>
			</LinkedContent>

			<div className="flex items-start flex-col gap-2">
				<LinkedContent
					isSingleStore={isSingleStore}
					href={`/products/${productId}`}
					bizUrl={bizUrl}
					underline={false}
				>
					<HiddenRowProductName hebName={hebName} engName={engName} />
				</LinkedContent>
				<div className="flex gap-1">
					<ProductTypeUI
						productType={listProductTypes.find(type => {
							return type.label === productType;
						})}
						variant={VARIANT.SMALLTEXT}
					/>
					<ProductTypeUI
						productType={listProductTypes.find(type => {
							return type.label === category;
						})}
						variant={VARIANT.SMALLTEXT}
					/>
					<ProductTypeUI
						productType={listProductTypes.find(type => {
							return type.label === family;
						})}
						variant={VARIANT.SMALLTEXT}
					/>
				</div>
				<div className="grid grid-cols-2 gap-x-3 gap-y-1 w-fit">
					<div className="flex gap-1">
						<span className="text-jane-500">THC</span>
						<span className="font-semibold">{getThcCbdRange(row, "thc")}</span>
					</div>
					<div className="flex gap-1">
						<span className="text-jane-500">CBD</span>
						<span className="font-semibold">{getThcCbdRange(row, "cbd")}</span>
					</div>
					{!!batch?.batch_id ? (
						<>
							<div className="flex gap-1">
								<span className="text-jane-500">אצווה</span>
								<span className="font-semibold">{batch.batch_id}</span>
							</div>
							{batch?.expiration_date && (
								<div className="flex gap-1">
									<span className="text-jane-500">תוקף</span>
									<span className="font-semibold">
										{batch.expiration_date.split("-")[1]}/
										{batch.expiration_date.split("-")[0].slice(-2)}
									</span>
								</div>
							)}
						</>
					) : !!storeProductDate ? (
						<div className="flex gap-1">
							<span className="text-jane-500">תוקף</span>
							<span className="font-semibold">{storeProductDate}</span>
						</div>
					) : null}
					<div className="flex gap-1 col-span-full">
						<span className="text-jane-500">מחיר</span>
						<span className="font-semibold">
							{isSingleStore
								? getPriceSingleStore(storePrice, catalogPrice)
								: getPrice(fromPrice, toPrice, catalogPrice, row.i_a_f_s)}
						</span>
					</div>
					{isSingleStore &&
						isLowStock(
							quantity,
							row.store.low_stock_product_quantity,
							row.store.show_low_stock_quantity,
						) && (
							<div className="flex gap-1 col-span-full">
								<span className="text-jane-500">מלאי</span>
								<span className="text-red-500 font-semibold">מלאי נמוך</span>
							</div>
						)}
				</div>

				{!!customActionButton ? (
					customActionButton(row)
				) : !tabletMode ? (
					<div className="flex gap-2">
						{withCartButton ? (
							<AddToCartButton
								storeId={storeId}
								product={row}
								isWidget={isWidget}
								type="singleStore"
								referral={referral}
							/>
						) : (
							<LinkedContent href={`/products/${productId}`} bizUrl={bizUrl}>
								<Button primary={!isSingleStore} className="flex items-center">
									<span>השוואת מחירים</span>
									<ChevronLeftIcon className="w-3.5 h-3.5" />
								</Button>
							</LinkedContent>
						)}
					</div>
				) : null}
			</div>
		</div>
	);
};

export default HiddenRowHeader;
