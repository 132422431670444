import { useLogin } from "components/hooks/useLogin";
import React, { useEffect, useState } from "react";
import IdStep from "./IdStep";
import CodeStep from "./CodeStep";
import RegisterStep from "./RegisterStep";
import { LOGIN_STEPS } from "util/types";
import { useRouter } from "next/router";
import { FaWhatsapp } from "react-icons/fa";
import { AtSymbolIcon } from "@heroicons/react/outline";
import HelpRow from "components/layout/HelpRow";

function OtpLoginCard({ onLogin = () => {}, skip, showHelp }) {
	const loginMethods = useLogin(onLogin);
	const { step } = loginMethods;
	const router = useRouter();

	const { LOGIN, CODE_LOGIN, REGISTER, WELCOME } = LOGIN_STEPS;

	const tokenLogin = async t => {
		await loginMethods.tokenLogin(t);
	};

	useEffect(() => {
		if (skip) {
			tokenLogin(router?.query?.t);
		}
	}, [skip]);

	return (
		<>
			{step === LOGIN ? (
				<IdStep {...loginMethods} />
			) : step === CODE_LOGIN ? (
				<CodeStep {...loginMethods} />
			) : step === REGISTER ? (
				<RegisterStep {...loginMethods} />
			) : step === WELCOME ? (
				<div>Welcome!</div>
			) : (
				<div>Default</div>
			)}
			{showHelp && <HelpRow />}
		</>
	);
}

export default OtpLoginCard;
