import { useIsInIframe } from "components/hooks/useIsInIframe";
import Link from "next/link";
import { useRouter } from "next/router";
import { isSubdomainRoute } from "pages/_middleware";
import React from "react";

const isWidgetRoute = route => {
	return route.startsWith("/widget/");
};

const LinkedContent = ({
	children,
	isSingleStore,
	href,
	bizUrl,
	underline = true,
	showUnknown = false,
}) => {
	const isInIframe = useIsInIframe();
	const { route } = useRouter();

	const isSubdomain = isSubdomainRoute(window.location.hostname);
	const isWidget = isWidgetRoute(route);

	if (showUnknown && !children) {
		return "לא ידוע";
	}

	// store subdomain or widget
	if (isSubdomain || (isSingleStore && isWidget && !isInIframe)) {
		return children;
	}

	// store widget iframe
	if (isInIframe && !!bizUrl && isWidget) {
		return (
			<a
				href={bizUrl}
				target="_blank"
				rel="noopener noreferrer"
				className={`cursor-pointer ${underline && "underline"}`}
			>
				{children}
			</a>
		);
	}
	if (!isInIframe && !!href) {
		const [pathname] = href.replace(/\//g, "").split("?");
		const cleanRoute = route.replace(/\//g, "");
		// If the link is to the same page, don't use Link
		if (pathname === cleanRoute) {
			return (
				<a
					href={href}
					rel="noopener noreferrer"
					className={`cursor-pointer ${underline && "underline"}`}
				>
					{children}
				</a>
			);
		} else {
			return (
				<Link href={href}>
					<a
						rel="noopener noreferrer"
						className={`cursor-pointer ${underline && "underline"}`}
					>
						{children}
					</a>
				</Link>
			);
		}
	}
	return children;
};

export default LinkedContent;
