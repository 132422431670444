import React, { useState } from "react";
import PageHeader from "./pageHeader";
import { useIsInIframe } from "components/hooks/useIsInIframe";
import useWindowDimensions from "components/hooks/useWindowDimensions";
import { WBO } from "util/types";
import { useRouter } from "next/router";

const TableLayout = ({
	children,
	header,
	breadcrumbs,
	isSingleStore = false,
}) => {
	const isInIframe = useIsInIframe();
	const { width, height } = useWindowDimensions();
	const [adLoaded, setAdLoaded] = useState({
		ad1: false,
		ad2: false,
		ad3: false,
	});
	const router = useRouter();
	const isWidget = router.pathname.includes("widget");
	const isCartOrCheckout =
		router.pathname.includes("cart") || router.pathname.includes("checkout");

	return (
		<div className="w-full bg-gray-100 min-h-screen">
			<div className={`main-width mx-auto ${isInIframe ? "px-2" : "my-6"}`}>
				<div className="flex justify-between w-full items-center">
					<div className="w-full">
						<div className="flex flex-col gap-2 xl:flex-row justify-center xl:gap-0 xl:justify-between">
							<PageHeader breadcrumbs={breadcrumbs} />
							{!isInIframe &&
								!isWidget &&
								!isSingleStore &&
								!isCartOrCheckout &&
								width < WBO["lg"] &&
								process.env.NODE_ENV === "production" && (
									<>
										<span
											id="zone886959479"
											className="flex justify-center xl:flex-none pb-[2px]"
										></span>
										<script>
											{typeof document !== "undefined" &&
												(function (r, d, u) {
													if (adLoaded.ad1) return;
													var s = d.createElement(r);
													s.async = true;
													u += "&cb=" + Date.now() + Math.random();
													s.src = u;
													var n = d.getElementsByTagName(r)[0];
													n.parentNode.insertBefore(s, n);
													setAdLoaded(ads => ({ ...ads, ad1: true }));
												})(
													"script",
													document,
													"https://knbis.engine.adglare.net/?886959479",
												)}
										</script>
									</>
								)}
							{isSingleStore &&
								!isCartOrCheckout &&
								!isWidget &&
								process.env.NODE_ENV === "production" && (
									<>
										<span
											id="zone103251186"
											className="flex justify-center xl:flex-none pb-[2px]"
										></span>
										<script>
											{typeof document !== "undefined" &&
												(function (r, d, u) {
													if (adLoaded.ad2) return;
													var s = d.createElement(r);
													s.async = true;
													u += "&cb=" + Date.now() + Math.random();
													s.src = u;
													var n = d.getElementsByTagName(r)[0];
													n.parentNode.insertBefore(s, n);
													setAdLoaded(ads => ({ ...ads, ad2: true }));
												})(
													"script",
													document,
													"https://knbis.engine.adglare.net/?103251186",
												)}
										</script>
									</>
								)}
						</div>
						<div className="py-3">
							<h1 className="text-2xl font-bold text-gray-900 sm:text-3xl leading-7 sm:truncate">
								{header}
							</h1>
						</div>
					</div>

					{!isInIframe &&
						!isWidget &&
						!isCartOrCheckout &&
						!isSingleStore &&
						WBO["lg"] < width &&
						process.env.NODE_ENV === "production" && (
							<>
								<span
									id="zone886959479"
									className="flex justify-center xl:flex-none pb-[2px]"
								></span>
								<script>
									{typeof document !== "undefined" &&
										(function (r, d, u) {
											if (adLoaded.ad3) return;
											var s = d.createElement(r);
											s.async = true;
											u += "&cb=" + Date.now() + Math.random();
											s.src = u;
											var n = d.getElementsByTagName(r)[0];
											n.parentNode.insertBefore(s, n);
											setAdLoaded(ads => ({ ...ads, ad3: true }));
										})(
											"script",
											document,
											"https://knbis.engine.adglare.net/?886959479",
										)}
								</script>
							</>
						)}
				</div>

				{children}
			</div>
		</div>
	);
};

export default TableLayout;
