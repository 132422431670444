import janeConfig from "jane.config";
import { NextResponse } from "next/server";

const opts = {
	credentials: "include",
	headers: {
		Accept: "application/json",
		"Content-Type": "application/json",
	},
};

export function isSubdomainRoute(hostname) {
	const currentEnv = process.env.NEXT_PUBLIC_JANE_ENV || "LOCAL";
	const envDomains = {
		production: ".jane.co.il",
		staging: ".jane-stage.com",
		develop: ".dev-jane.com",
	};
	return hostname.includes(envDomains[currentEnv]);
}

async function sendErrorEmail(message) {
	const params = {
		message: message,
	};
	const redirect = getRedirectUrl(false);
	await fetch(`${redirect}/api/send-error-email/`, {
		...opts,
		method: "POST",
		body: JSON.stringify(params),
	});
}

async function isSubdomainValidStoreId(hostname) {
	const [subdomain] = hostname.split(".");
	const redirect = getRedirectUrl(false);

	try {
		const response = await fetch(`${redirect}/api/store-eng-names-cache`, {
			...opts,
			cache: "no-store",
		});
		if (response.ok) {
			const data = await response.json();
			console.log(
				"middleware: response data is ok: ",
				Object.keys(data.data).length,
			);
			return data.data?.[subdomain];
		} else {
			console.log(
				`middleware: Failed to fetch store-eng-names-cache: ${hostname} \n response: ${response}`,
			);
		}
	} catch (error) {
		console.error("middleware: Failed to validate subdomain:", error);
	}
	return false;
}

function getRedirectUrl(isLocal = false) {
	const currentEnv = process.env.NEXT_PUBLIC_JANE_ENV || "LOCAL";
	switch (currentEnv) {
		case "production":
			return janeConfig.productionUrl;
		case "staging":
			return janeConfig.stagingUrl;
		case "develop":
			return janeConfig.devUrl;
		default:
			if (isLocal) return "http://localhost:3000";
			return "http://localhost:9040";
	}
}

async function processShortCode(storeId, shortCode, request, opts) {
	const redirect = getRedirectUrl(false);
	try {
		const response = await fetch(
			`${redirect}/api/stores/${storeId}/short-link/${shortCode}/`,
			opts,
		);
		if (!response.ok) {
			throw new Error("Short code fetch failed");
		}

		const shortCodeData = await response.json();
		const url = new URL("", request.url);

		// Handle filters
		if (shortCodeData.data.filter_by?.length) {
			const filterString = shortCodeData.data.filter_by
				.map(filter => {
					const property = filter.property;
					const value = Array.isArray(filter.value)
						? filter.value.join(",")
						: filter.value;
					return `${property}^${filter.condition}^${value}`;
				})
				.join(";");
			url.searchParams.set("filters", filterString);
		}

		// Handle sort
		if (shortCodeData.data.sort_by) {
			url.searchParams.set("sortBy", shortCodeData.data.sort_by);
		}

		return { url, success: true };
	} catch (error) {
		console.error("Error processing short code:", error);
		return { url: null, success: false };
	}
}

export async function middleware(request) {
	if (request.method === "HEAD" || request.nextUrl.pathname.includes(".")) {
		return NextResponse.next();
	}
	console.log("***MIDDLEWARE LOGS START***");

	const hostname = request.headers.get("host");
	const { pathname, search } = request.nextUrl;

	console.log("middleware: before issubdomainroute.");
	if (isSubdomainRoute(hostname)) {
		console.log("middleware: in issubdomainroute.");
		const [subdomain] = hostname.split(".");
		console.log("middleware: before validated subdomain: ", hostname);
		const validSubdomain = await isSubdomainValidStoreId(hostname);
		// If subdomain is not existing store name, redirect to main domain
		if (!validSubdomain) {
			console.log(
				"middleware: isSubdomainValid failed, redirecting to /products.",
			);
			await sendErrorEmail(
				`Invalid subdomain: ${subdomain}, redirecting to /products.`,
			);
			return NextResponse.redirect(getRedirectUrl(true) + "/products");
		}
		console.log("middleware: isSubdomainValid valid");
		// If current subdomain doesn't match the latest one, redirect to the new subdomain
		if (subdomain !== validSubdomain) {
			console.log("middleware: subdomain and validSubdomain dont match");
			const baseUrl = getRedirectUrl(true);
			const [protocol, domain] = baseUrl.split("//");
			const redirectUrl = `${protocol}//${validSubdomain}.${domain}${pathname}${search}`;
			return NextResponse.redirect(redirectUrl);
		}

		if (
			pathname.startsWith("/?") &&
			(query.has("filters") || query.has("sortby") || query.has("query"))
		) {
			return NextResponse.next();
		}
		console.log("middleware: before shortCodeMatch");
		const shortCodeMatch = pathname.match(/^\/[^\/]+\/?$/);
		if (shortCodeMatch) {
			const shortCode = shortCodeMatch[0].replaceAll("/", "");
			const { url, success } = await processShortCode(
				subdomain,
				shortCode,
				request,
				opts,
			);
			const baseRedirect = getRedirectUrl(true);
			const splitUrl = baseRedirect.split("//");
			if (success) {
				console.log("middleware: shortCodeMatch success");
				url.pathname = "/";
				url.hostname = `${splitUrl[0]}//${subdomain}.${splitUrl[1]}`;
				return NextResponse.redirect(url);
			}
			console.log("middleware: shortCodeMatch failed");
			return NextResponse.redirect(
				`${splitUrl[0]}//${subdomain}.${splitUrl[1]}`,
			);
		}
		return NextResponse.next();
	}

	// Handle regular domain shortcode pattern
	const isStorePage = pathname.match(/^\/store\/([^\/]+)/);

	if (isStorePage) {
		console.log("middleware: in isStorePage");
		const shortCodeMatch = pathname.match(/^\/store\/([^\/]+)\/([^\/]+)/);
		if (shortCodeMatch) {
			console.log(
				"middleware: in shortCodeMatch storepage, shortcode: ",
				shortCode,
			);
			const [_, storeId, shortCode] = shortCodeMatch;

			const { url, success } = await processShortCode(
				storeId,
				shortCode,
				request,
				opts,
			);

			if (success) {
				console.log("middleware: shortCodeMatch success storepage");
				const baseRedirect = getRedirectUrl(true);
				url.pathname = `/store/${storeId}`;
				url.hostname = `${baseRedirect}`;
				return NextResponse.redirect(url);
			}
			console.log("middleware: shortCodeMatch failed storepage");
			const storeRedirect = getRedirectUrl(true) + "/store/" + storeId;
			return NextResponse.redirect(storeRedirect);
		}
	}

	if (pathname === "/") {
		console.log("middleware: in pathname /");
		return NextResponse.redirect(getRedirectUrl(true) + "/products");
	}
	console.log("***MIDDLEWARE LOGS END***");

	return NextResponse.next();
}

export const config = {
	matcher: [
		"/((?!_next/static|_next/image|favicon.ico|api|\\.css|\\.css\\.map|\\.js|\\.json|\\.svg|\\.png|\\.jpg|\\.jpeg|\\.webp).*)",
	],
};
