import { AtSymbolIcon, ChevronRightIcon } from "@heroicons/react/outline";
import { useRouter } from "next/router";
import { useState } from "react";
import { FaWhatsapp } from "react-icons/fa";

export default function HelpBox() {
	const [isExpanded, setIsExpanded] = useState(false);

	const router = useRouter();

	if (
		!router.pathname.includes("/cart") &&
		!router.pathname.includes("checkout")
	) {
		return null;
	}

	return (
		<div className="fixed bottom-[80px] left-0 transform -translate-y-1/2 z-50">
			<div
				className={`bg-white shadow-lg transition-all duration-300 border-[1px] border-black border-l-0 rounded-r-lg ${
					isExpanded ? "w-44" : "w-6"
				} flex items-center`}
			>
				<button
					onClick={() => setIsExpanded(!isExpanded)}
					className={`flex items-start font-light  rounded-r-md leading-none w-6 flex-col justify-center h-14 gap-1 ${
						!isExpanded ? "text-jane-500 " : "bg-jane-500 text-white"
					}`}
				>
					<span className="transform origin-center transition-transform duration-300 font-semibold -rotate-90 text-sm">
						{isExpanded ? "סגור" : "עזרה"}
					</span>
					<span
						className={`transform transition-transform mr-[7px] mt-1 duration-300 ${
							isExpanded ? "rotate-180" : ""
						}`}
					>
						<ChevronRightIcon className="w-2.5 h-2.5" />
					</span>
				</button>
				{isExpanded && (
					<div className="text-sm flex flex-col w-full items-center font-medium text-gray-700 transition-opacity duration-1000 whitespace-nowrap">
						<span>כתבו לנו:</span>
						<div className="flex items-center gap-2">
							<a
								target="_blank"
								rel="noreferrer noopener"
								className="flex items-center gap-0.5"
								href="https://wa.me/0584204401"
							>
								<FaWhatsapp className="w-4 h-4" />
								<span className="underline">בוואטסאפ</span>
							</a>
							<a
								className="flex items-center gap-0.5"
								href="mailto:info@jane.co.il"
							>
								<AtSymbolIcon className="w-4 h-4" />
								<span className="underline">באימייל</span>
							</a>
						</div>
					</div>
				)}
			</div>
		</div>
	);
}
