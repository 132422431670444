import Link from "next/link";
import React, { Fragment } from "react";
import StrainParentTooltip from "./StrainParentTooltip";
import { XIcon } from "@heroicons/react/solid";
import { classNames, isWidgetRoute } from "util/common";

function StrainList({
	parents = [],
	isProductView = false,
	disableLinks = false,
}) {
	const isWidget = isWidgetRoute();

	if (parents.length <= 0) {
		return (
			<div
				className={
					isProductView
						? " font-semibold text-black"
						: "text-[#1E293B] text-[14px] md:text-[15px] font-semibold inline-block"
				}
			>
				לא ידוע
			</div>
		);
	}
	const newParents = parents.filter(parent => !!parent?.id);
	return (
		<>
			{newParents.map((parent, index) => {
				const heb_name =
					parent?.heb_name?.trim() || parent?.parent_heb_name?.trim() || "";

				if (!parent?.id) {
					return;
				}

				//* DISABLED LINKS *//
				if (disableLinks) {
					return (
						<Fragment key={index}>
							<div className="inline whitespace-nowrap">{heb_name}</div>
							<StrainParentTooltip
								strainId={parent?.id}
								disableLinks={disableLinks}
							/>
							{newParents.length - 1 === index ? null : (
								<span className="h-5 flex items-center">
									<XIcon className="w-3 h-3 text-jane-600" />
								</span>
							)}
						</Fragment>
					);
				}

				//* /WIDGET ROUTE *//
				if (isWidget) {
					return (
						<Fragment key={index}>
							<a
								href={parent?.biz_url}
								target="_blank"
								rel="noreferrer"
								className=" inline underline whitespace-nowrap"
							>
								{heb_name}
							</a>
							<StrainParentTooltip
								strainId={parent?.id}
								disableLinks={disableLinks}
							/>
							{newParents.length - 1 === index ? null : (
								<span className="h-5 flex items-center">
									<XIcon className="w-3 h-3 text-jane-600" />
								</span>
							)}
						</Fragment>
					);
				}

				//** DEFAULT RETURN **/
				return (
					<Fragment key={index}>
						<Link href={`/strain/${parent?.id}`}>
							<a
								className={classNames(
									" underline items-center whitespace-nowrap",
									isProductView && " text-black ",
								)}
								target={!isProductView ? "_blank" : "_self"}
							>
								{heb_name}
							</a>
						</Link>
						<StrainParentTooltip
							strainId={parent?.id}
							disableLinks={disableLinks}
						/>
						{newParents.length - 1 === index ? null : (
							<span className="h-5 flex items-center">
								<XIcon className="w-3 h-3 text-jane-600" />
							</span>
						)}
					</Fragment>
				);
			})}
		</>
	);
}

export default StrainList;
