/* eslint-disable @next/next/no-img-element */
import React, {
	Fragment,
	useContext,
	useMemo,
	useEffect,
	useRef,
	createRef,
} from "react";
import { Disclosure, Menu, Transition } from "@headlessui/react";
import { MenuIcon, SearchIcon, XIcon } from "@heroicons/react/outline";
import { useRouter } from "next/router";
import Link from "next/link";
import { classNames } from "util/common";
import { AuthContext } from "../contexts/AuthContext";
import { StoresCartsHeader } from "components/account/StoresCartsHeader";
import { LoginBoxModal } from "components/modals/LoginBoxModal";
import HoverMenu from "./HoverMenu";
import { AnalyticsContext } from "components/contexts/AnalyticsProvider";
import { MdAccountCircle } from "react-icons/md";
import { FaChevronDown } from "react-icons/fa";
import { useAtom } from "jotai";
import { showSearchbarAtom } from "components/jotai/searchAtom";
import useWindowDimensions from "components/hooks/useWindowDimensions";
import { WBO } from "util/types";
import GlobalSearchBar from "components/ui/globalSearch/GlobalSearchBar";
import Image from "next/image";
import useEnv from "components/hooks/useEnv";

export default function HoverTopbar({}) {
	const { authenticated, claims, logout } = useContext(AuthContext);

	const [showSearchbar, setShowSearchbar] = useAtom(showSearchbarAtom);
	const { collectAnalytics } = useContext(AnalyticsContext);
	const { width } = useWindowDimensions();
	const { env } = useEnv();
	const router = useRouter();
	const { pathname } = router;
	const isCartOrCheckout =
		router.pathname.includes("/checkout") || router.pathname.includes("/cart");

	const mobileMenuRef = useRef(null);
	const searchIconMobileRef = useRef(null);
	const searchIconMobileLoggedInRef = useRef(null);
	const mobileMenuPanelRef = useRef(null);
	const navRef = useRef(null);

	useEffect(() => {
		const handleClickOutside = event => {
			const isMenuOpen =
				mobileMenuRef.current?.getAttribute("data-open") === "true";

			if (!isMenuOpen) return;

			const isOutsideMenu =
				!mobileMenuRef.current?.contains(event.target) &&
				!mobileMenuPanelRef.current?.contains(event.target);

			if (isOutsideMenu) {
				mobileMenuRef.current?.click();
			}
		};

		document.addEventListener("click", handleClickOutside);
		return () => {
			document.removeEventListener("click", handleClickOutside);
		};
	}, []);

	const menuItems = useMemo(
		() =>
			[
				{
					id: "products",
					label: "מוצרים",
					path: "/products",
					subMenuItems: [
						{
							label: "כל המוצרים",
							path: "/products",
							hide: WBO["md"] < width,
						},
						{
							label: "הנצפים",
							path: "/products/most-viewed",
						},
						{
							label: "חדשים",
							path: "/products/newest",
						},
						{
							label: "בקרוב",
							path: "/products/soon",
						},
						{
							label: "גנטיקות",
							path: "/strains",
						},
					],
				},
				{
					id: "stores",
					label: "בתי מרקחת",
					path: "/stores",
					subMenuItems: [
						{
							label: "כל בתי המרקחת",
							path: "/stores",
							hide: WBO["md"] < width,
						},
						{ label: "לפי ערים", path: "/cities" },
						{ label: "ממוצע הנחות", path: "/stores/avg-discounts" },
					],
				},
				{
					id: "manufacturers",
					label: "יצרנים",
					subMenuItems: [
						{ label: "מגדלים", path: "/manufacturers" },
						{ label: "משווקים", path: "/marketers" },
						// { label: "מטפחים", path: "???" }, IN THE FUTURE - Does not exist now
					],
				},
				{ id: "desired-cart", label: "הסל הזול", path: "/desired-cart" },
				{ id: "reviews", label: "ביקורות", path: "/reviews" },
			].map(item => {
				// Check if the main item or any of the sub-menu items is active
				const isOpen = item.subMenuItems
					? item.subMenuItems.some(sub => sub.path === pathname)
					: item.path === pathname;

				const updatedSubMenuItems = item.subMenuItems?.map(subMenuItem => {
					return {
						...subMenuItem,
						isOpen: subMenuItem.path == pathname,
					};
				});

				return { ...item, isOpen, subMenuItems: updatedSubMenuItems };
			}),
		[pathname, width],
	);

	let fullName = "";
	let email = "";

	if (authenticated) {
		fullName = claims.full_name;
		email = claims.email;
	}

	const refs = useMemo(() => {
		return (
			menuItems.map(() => {
				return createRef();
			}) ?? []
		);
	}, []);

	const subRefs = useMemo(() => {
		const subMenuItems = menuItems
			?.filter(item => item.subMenuItems?.map(si => si))
			.flat();
		return (
			subMenuItems?.map(() => {
				return createRef();
			}) ?? []
		);
	}, [menuItems]);

	function handleClosingOthers(id, currRef, menuOpen, isSubRef = false) {
		if (menuOpen && !isSubRef) {
			currRef.current?.click();
		}

		if (isSubRef) {
			const otherSubRefs = subRefs.filter(ref => {
				return ref?.current?.getAttribute("data-id") !== id;
			});

			otherSubRefs.forEach(ref => {
				const isOpen = ref?.current?.getAttribute("data-open") === "true";
				if (isOpen) {
					ref.current?.click();
				}
			});

			if (menuOpen) {
				currRef.current?.click();
			}

			return;
		}

		const otherRefs = refs.filter(ref => {
			return ref?.current?.getAttribute("data-id") !== id;
		});

		otherRefs.forEach(ref => {
			const isOpen = ref?.current?.getAttribute("data-open") === "true";
			if (isOpen) {
				ref.current?.click();
			}
		});

		if (menuOpen) {
			currRef.current?.click();
		}
	}

	return (
		<Disclosure
			as="nav"
			ref={navRef}
			className="bg-white shadow fixed w-full z-50 text-base sm:text-sm"
		>
			{({ open, close }) => (
				<>
					<div className="flex flex-col w-full">
						<div className="h-14 lg:h-16 relative flex flex-col lg:flex-row gap-2 main-width mx-auto px-2 md:px-4 justify-center">
							<div className="relative flex main-width ">
								<div className="flex items-center gap-3 lg:hidden">
									{/* Mobile menu button */}
									<Disclosure.Button
										onClick={() => {
											collectAnalytics("mobile_menu_clicked");
											setShowSearchbar(false);
										}}
										ref={mobileMenuRef}
										as="div"
										data-open={open}
										className="inline-flex items-center justify-center rounded-md"
									>
										{open ? (
											<XIcon
												className="h-6 w-6 pointer-events-none"
												aria-hidden="true"
											/>
										) : (
											<MenuIcon
												className="h-6 w-6 pointer-events-none"
												aria-hidden="true"
											/>
										)}
									</Disclosure.Button>
									<StoresCartsHeader />
								</div>
								<div className="flex-1 flex items-center justify-center lg:items-center lg:justify-start h-full">
									<div className="flex h-full flex-grow justify-center">
										<div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 lg:static lg:flex lg:h-full lg:transform-none lg:flex-shrink-0  lg:items-center">
											<Link href="/products/">
												<a className="relative block h-[32px] lg:h-[36px] w-[120px] lg:w-[140px]">
													<Image
														src="/jane-logo.svg"
														alt="Jane"
														layout="fill"
														objectFit="contain"
													/>
													{env !== "production" && (
														<div
															className={`absolute -bottom-3 lg:-bottom-[14px] left-0 right-0 w-full text-center leading-none px-2 rounded-t-full text-white font-bold text-sm
														${
															env === "staging"
																? "bg-blue-500"
																: env === "develop"
																? "bg-pink-500"
																: "bg-jane-500"
														}
														`}
														>
															{env}
														</div>
													)}
												</a>
											</Link>
										</div>

										<div className="hidden ms-4 lg:flex h-full w-full">
											{menuItems
												?.filter(item => !item.hide)
												.map((item, idx) => {
													return (
														<div key={idx} className="flex items-center h-full">
															{item.subMenuItems ? (
																<HoverMenu
																	key={idx}
																	name={item.label}
																	menuItems={item.subMenuItems.filter(
																		item => !item.hide,
																	)}
																	className={`
																		border-b-2 hover:border-jane-500
																		${item.isOpen ? "bg-jane-100 border-jane-500" : "border-transparent"}`}
																	isLinkAlsoAButton={item.path ? true : false}
																	url={item.path}
																/>
															) : item?.path.includes("desired-cart") &&
															  router.query?.pl?.length ? (
																<div
																	onClick={() => {
																		window.location.href = item.path;
																	}}
																	className={classNames(
																		`box-border px-4 text-base whitespace-nowrap font-medium cursor-pointer border-b-2 hover:border-jane-500 border-transparent hover:bg-jane-100 h-full flex items-center gap-1`,
																		item.isOpen &&
																			"bg-jane-100 border-jane-500",
																		"bg-red-500",
																	)}
																>
																	{item.label}
																</div>
															) : (
																<Link href={item.path}>
																	<a
																		className={classNames(
																			`box-border px-4 text-base whitespace-nowrap font-medium cursor-pointer border-b-2 hover:border-jane-500 border-transparent hover:bg-jane-100 h-full flex items-center gap-1`,
																			item.isOpen &&
																				"bg-jane-100 border-jane-500",
																		)}
																	>
																		{item.label}
																	</a>
																</Link>
															)}
														</div>
													);
												})}
										</div>

										{!isCartOrCheckout && (
											<div className="w-full hidden lg:flex lg:items-center">
												<GlobalSearchBar />
											</div>
										)}
									</div>
								</div>

								{/* Mobile */}
								{!authenticated ? (
									<div className="flex gap-3 lg:mr-6 items-center">
										<div className="hidden lg:flex">
											<StoresCartsHeader />
										</div>
										{!isCartOrCheckout && (
											<span className="lg:hidden" ref={searchIconMobileRef}>
												<SearchIcon
													className="w-6 h-6"
													onClick={() => {
														setShowSearchbar(prev => !prev);
														const isMobileMenuOpen =
															mobileMenuRef.current?.getAttribute(
																"data-open",
															) === "true";
														if (isMobileMenuOpen) {
															mobileMenuRef.current?.click();
														}
													}}
												/>
											</span>
										)}
										<LoginBoxModal
											activator={
												<div className="cursor-pointer hover:underline font-medium">
													<MdAccountCircle className="w-6 h-6 rounded-full text-white bg-black" />
												</div>
											}
										/>
									</div>
								) : (
									<div className="flex items-center gap-3">
										{!isCartOrCheckout && (
											<span
												className="lg:hidden"
												ref={searchIconMobileLoggedInRef}
											>
												<SearchIcon
													className="w-6 h-6"
													onClick={() => {
														setShowSearchbar(prev => !prev);
														const isMobileMenuOpen =
															mobileMenuRef.current?.getAttribute(
																"data-open",
															) === "true";
														if (isMobileMenuOpen) {
															mobileMenuRef.current?.click();
														}
													}}
												/>
											</span>
										)}
										<Menu
											as="div"
											className="relative flex lg:gap-3 lg:mr-6 items-center h-full"
										>
											<div className="hidden lg:flex">
												<StoresCartsHeader />
											</div>
											<div className="flex items-center justify-center">
												<Menu.Button
													className={`bg-white rounded-full flex gap-1 text-sm items-center flex-row-reverse sm:flex-row ${
														authenticated &&
														"border-2 p-[1px] border-emerald-500"
													}`}
												>
													<MdAccountCircle className="w-6 h-6 rounded-full text-white bg-jane-800" />
												</Menu.Button>
											</div>
											{/* Profile dropdown */}
											<div className="flex">
												<Transition
													as={Fragment}
													enter="transition ease-out duration-200"
													enterFrom="transform opacity-0 scale-95"
													enterTo="transform opacity-100 scale-100"
													leave="transition ease-in duration-75"
													leaveFrom="transform opacity-100 scale-100"
													leaveTo="transform opacity-0 scale-95"
												>
													<Menu.Items className="absolute top-full mt-2 left-0 w-48 rounded-md shadow-sm py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-50">
														<div className="px-4 py-3 text-xs">
															<div className="text-jane-500">{fullName}</div>
															<div className="text-jane-600">{email}</div>
														</div>
														<Menu.Item>
															{({ active }) => (
																<div
																	onClick={() => router.push("/account/")}
																	className={classNames(
																		active ? "bg-jane-100" : "",
																		"block px-4 py-3 text-sm text-jane-700 cursor-pointer",
																	)}
																>
																	החשבון שלי
																</div>
															)}
														</Menu.Item>
														<Menu.Item>
															{({ active }) => (
																<div
																	onClick={() => logout()}
																	className={classNames(
																		active ? "bg-jane-100" : "",
																		"block px-4 py-3 text-sm text-jane-700 cursor-pointer",
																	)}
																>
																	יציאה
																</div>
															)}
														</Menu.Item>
													</Menu.Items>
												</Transition>
											</div>
										</Menu>
									</div>
								)}
							</div>
							{!isCartOrCheckout && showSearchbar && (
								<div className=" bg-white main-width shadow-sm  lg:hidden flex flex-col absolute top-14 w-full right-0 px-2 pb-1">
									<GlobalSearchBar shouldFocus />
								</div>
							)}
						</div>
					</div>

					<Disclosure.Panel
						className="lg:hidden"
						ref={mobileMenuPanelRef}
						as="div"
						data-open={open}
					>
						<div
							className={`px-2 py-4 text-base flex flex-col gap-2 select-none ${
								open && "shadow-lg"
							}`}
						>
							{menuItems.map((item, idx) => (
								<div key={idx}>
									<Disclosure defaultOpen={item.isOpen}>
										{({ open: menuOpen, close: menuClose }) => {
											return (
												<>
													{item.subMenuItems ? (
														<>
															<Disclosure.Button
																key={idx}
																ref={refs[idx] ? refs[idx] : null}
																data-id={item.id}
																data-open={menuOpen}
																as="div"
																onClick={() => {
																	handleClosingOthers(
																		item.id,
																		refs[idx],
																		menuOpen,
																	);
																}}
																className={classNames(
																	"flex text-lg py-2 px-5 rounded-md justify-between items-center text-jane-800 font-medium cursor-pointer",
																	menuOpen
																		? "bg-jane-200 border-jane-500 "
																		: "border-transparent hover:bg-jane-50 hover:border-jane-300",
																)}
															>
																{item.label}
																{item.subMenuItems?.length > 1 && (
																	<FaChevronDown
																		className={`transition-transform h-4 w-4 ${
																			menuOpen ? "rotate-180" : "rotate-0"
																		}`}
																	/>
																)}
															</Disclosure.Button>
															<Disclosure.Panel
																as="div"
																className="flex flex-col gap-2 mt-2 rounded-md bg-jane-100 p-2 text-lg"
															>
																{item.subMenuItems?.map(
																	(subMenuItem, subIdx) => {
																		return (
																			<Link
																				key={subIdx}
																				href={subMenuItem.path}
																			>
																				<a
																					className={`flex items-center gap-2 px-0.5 py-2 w-full h-full ${
																						subMenuItem.isOpen &&
																						"font-semibold"
																					}`}
																					onClick={close}
																				>
																					<div
																						className={`${
																							subMenuItem.isOpen &&
																							"bg-jane-500"
																						} rounded-full h-6 w-1`}
																					/>
																					{subMenuItem.label}
																				</a>
																			</Link>
																		);
																	},
																)}
															</Disclosure.Panel>
														</>
													) : (
														<Link key={item.path} href={item.path}>
															<a
																className={`flex gap-2 items-center p-2 text-lg font-medium text-jane-800 hover:bg-jane-50 cursor-pointer ${
																	item.isOpen && "font-semibold"
																}`}
																onClick={close}
															>
																<div
																	className={`${
																		item.isOpen && "bg-jane-500"
																	} rounded-full h-6 w-1`}
																/>
																<span>{item.label}</span>
															</a>
														</Link>
													)}
												</>
											);
										}}
									</Disclosure>
								</div>
							))}
						</div>
					</Disclosure.Panel>
				</>
			)}
		</Disclosure>
	);
}
