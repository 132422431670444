export const Toggle = ({
	onToggle = () => {},
	activeColor = "lime-500", // tailwind color
	disabledColor = "gray-200", // tailwind color
	text = "",
	disabled,
	checked = false,
}) => {
	return (
		<div className="flex items-center flex-row">
			<div className="h-4">
				<label className="inline-flex relative items-center cursor-pointer">
					<input
						onChange={onToggle}
						type="checkbox"
						disabled={disabled}
						id="default-toggle"
						className="sr-only peer"
						checked={checked}
					/>
					<div
						className={`w-7 h-4 bg-${disabledColor} ${
							disabled ? "cursor-not-allowed opacity-50" : "cursor-pointer"
						}
             rounded-full peer
             font-medium focus:outline-none focus:ring-2
             peer-checked:after:translate-x-full
             peer-checked:after:border-white after:content-['']
             after:absolute after:top-[2px] after:left-[2px]
             after:bg-white after:border
             after:rounded-full after:h-3 after:w-3 after:transition-all
             peer-checked:bg-lime-500`}
					></div>
				</label>
			</div>
			{!!text && <p className="block text-xs text-gray-700 mr-2">{text}</p>}
		</div>
	);
};
