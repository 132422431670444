import { atomWithStorage } from "jotai/utils";
import { useAtom, atom } from "jotai";
import moment from "moment";
import { useContext } from "react";
import { AnalyticsContext } from "components/contexts/AnalyticsProvider";
import { toastMessage } from "util/common";
import { COUNTRIES } from "util/countryListIcons";
import { listProductTypes } from "util/types";
import { useApi } from "components/hooks/useApi";

export const userCartsAtom = atomWithStorage("janeCartsV2", []);

export const widgetOrderModalAtom = atom(false);
export const widgetCartModalAtom = atom(false);
export const useUserCarts = () => {
	const [userCarts, setUserCarts] = useAtom(userCartsAtom);
	const [widgetOrderModal, setWidgetOrderModal] = useAtom(widgetOrderModalAtom);
	const [widgetCartModal, setWidgetCartModal] = useAtom(widgetCartModalAtom);

	const { collectAnalytics, collectAnalyticsGoogle } =
		useContext(AnalyticsContext);
	const api = useApi();

	const addToCart = (
		storeId,
		newProduct,
		shouldToast = true,
		referral = "Jane",
		shouldCollectAnalytics = true,
	) => {
		// When we add product from product page its 'store' object has no 'city' object inside
		// So when there's no city object we insert it from 'product' object
		if (!newProduct?.store?.city && !!newProduct.city) {
			newProduct.store.city = newProduct.city;
		}

		// When we add product from product page it has 'product' object nested inside
		// So we extract product data into main object (for cart ui purposes)
		if (!!newProduct.product) {
			// This is for new superpharm duplicate skus case, we need exact sku_code
			const sku_code = newProduct.sku_code;
			newProduct = { ...newProduct, ...newProduct.product };
			newProduct.sku_code = sku_code;
		}
		setUserCarts(prevCarts => {
			const updatedCarts = [...prevCarts];
			const totalItemsInCart = getTotalItemsInCart(storeId);
			if (!!totalItemsInCart && totalItemsInCart >= 20) {
				toastMessage("ניתן להוסיף עד 20 מוצרים לסל", "error");
				shouldToast = false;
				return updatedCarts;
			}

			const cartIndex = updatedCarts.findIndex(
				cart => cart.storeId === storeId,
			);
			const newProductWithId = {
				...newProduct,
				product_with_batch_id: `${newProduct.product.id}+${
					newProduct.batch_id ? newProduct.batch_id : "noBatch"
				}+${newProduct.sku_code ? newProduct.sku_code : "noSku"}`,
			};

			if (cartIndex === -1) {
				//Create new cart, update local and ls, add product
				const newProductWithCount = { ...newProductWithId, count: 1 };

				const newCart = {
					storeId: storeId,
					products: [newProductWithCount],
					createdAt: moment().format(),
					updatedAt: moment().format(),
					store: newProductWithId.store,
				};
				return [...updatedCarts, newCart];
			} else {
				// Already has cart
				const storeCart = { ...updatedCarts[cartIndex] };
				const productIndex = storeCart.products.findIndex(product => {
					return (
						product.product_with_batch_id ===
						newProductWithId.product_with_batch_id
					);
				});
				if (productIndex === -1) {
					// Already has cart - no item
					const newProductWithCount = { ...newProductWithId, count: 1 };
					storeCart.products.push(newProductWithCount);
					storeCart.updatedAt = moment().format();
					updatedCarts[cartIndex] = storeCart;
					return updatedCarts;
					//
				} else {
					// Already has cart - has item
					if (
						newProduct.quantity &&
						storeCart.products[productIndex].count >= newProduct.quantity
					) {
						shouldToast = false;
						toastMessage("המלאי מוגבל, לא ניתן להוסיף יחידות נוספות", "error");
						return updatedCarts;
					}
					storeCart.products[productIndex].count += 1;
					storeCart.updatedAt = moment().format();
					updatedCarts[cartIndex] = storeCart;
					return updatedCarts;
				}
			}
		});
		collectAnalytics("add_to_cart", { store_id: storeId, product: newProduct });
		if (shouldCollectAnalytics) {
			const { product, store_price } = newProduct;
			const firstParents =
				product?.parent_strains_heb_name?.map(hebName =>
					hebName?.replace("'", "").trim(),
				) ?? [];

			const secondParents =
				product?.parents_second_strains_heb_name?.map(hebName =>
					hebName.replace("'", "").trim(),
				) ?? [];

			const parents = [...firstParents, ...secondParents].join(", ");

			const country = COUNTRIES.find(
				country => country.code === product?.origin_country,
			)?.heb_name;

			const productType = listProductTypes.find(type => {
				return type.label === product.product_type;
			}).longLabel;

			const family = listProductTypes.find(type => {
				return type.label === product.family;
			}).longLabel;

			const cityHebName = newProduct?.store?.city?.heb_name;

			const affiliation = newProduct?.display_name
				? `${newProduct.display_name} - ${cityHebName}`
				: `${newProduct?.store?.display_name} - ${cityHebName}`;

			const eventObject = {
				event: "add_to_cart",
				ecommerce: {
					items: [
						{
							item_name: `${product.heb_name} ${product.eng_name}`,
							item_id: product.id,
							price: store_price,
							currency: "ILS",
							manufacturer_series: product?.manufacturer_series_heb_name,
							heb_name: product.heb_name,
							eng_name: product.eng_name,
							product_type: productType,
							item_category: product.category,
							family: family,
							manufacturer:
								product?.manufacturer_heb_name ??
								newProduct?.manufacturer_heb_name,
							marketer:
								product?.marketer_heb_name ?? newProduct?.marketer_heb_name,
							parents: parents,
							series: product?.series_heb_name,
							item_variant: country,
							website: referral,
							affiliation: affiliation,
							quantity: "1",
						},
					],
				},
			};
			collectAnalyticsGoogle(eventObject);
		}

		if (shouldToast) {
			toastMessage("המוצר נוסף לסל בהצלחה", "success");
		}
	};

	const removeFromCart = (storeId, productToRemove) => {
		setUserCarts(prevCarts => {
			const updatedCarts = [...prevCarts];
			const cartIndex = updatedCarts.findIndex(
				cart => cart.storeId === storeId,
			);

			const newProductWithId = {
				...productToRemove,
				product_with_batch_id: `${productToRemove.product.id}+${
					productToRemove.batch_id ? productToRemove.batch_id : "noBatch"
				}+${productToRemove.sku_code ? productToRemove.sku_code : "noSku"}`,
			};

			if (cartIndex === -1) {
				// No cart
			} else {
				// Has cart
				const storeCart = { ...updatedCarts[cartIndex] };

				const productIndex = storeCart.products.findIndex(
					product =>
						product.product_with_batch_id ===
						newProductWithId.product_with_batch_id,
				);

				if (productIndex === -1) {
					// Has cart - no item
					// setError("no product in cart");
				} else {
					// Has cart - has item
					if (storeCart.products[productIndex]["count"] === 1) {
						// Count is 1 - remove product
						const newCartProducts = storeCart.products.filter(
							product =>
								product.product_with_batch_id !==
								newProductWithId.product_with_batch_id,
						);
						storeCart.products = newCartProducts;
						storeCart.updatedAt = moment().format();
						if (storeCart.products.length === 0) {
							// If no products left in cart - remove cart
							updatedCarts = updatedCarts.filter(
								cart => cart.storeId !== storeId,
							);
							return updatedCarts;
						} else {
							// Update cart
							updatedCarts[cartIndex] = storeCart;
							return updatedCarts;
						}
					} else {
						// Has item - count is not 1
						storeCart.products[productIndex]["count"] -= 1;
						storeCart.updatedAt = moment().format();
						updatedCarts[cartIndex] = storeCart;
						return updatedCarts;
					}
				}
			}
		});
		collectAnalytics("remove_from_cart", {
			store_id: storeId,
			product: productToRemove,
		});
	};

	const removeProductFromCart = (storeId, productToRemove) => {
		setUserCarts(prevCarts => {
			const newCarts = prevCarts.map(cart => {
				if (cart.storeId == storeId) {
					const filteredProducts = cart.products.filter(
						product =>
							!(
								product.id == productToRemove.id &&
								product.batch_id == productToRemove.batch_id
							),
					);
					const newCart = {
						...cart,
						products: filteredProducts,
					};
					return newCart;
				} else {
					return cart;
				}
			});
			return newCarts;
		});
	};

	const removeCart = storeId => {
		setUserCarts(prevCarts => {
			return prevCarts.filter(cart => cart.storeId != storeId);
		});
		collectAnalytics("remove_cart", { store_id: storeId });
	};

	const updateProductCountInCart = (storeId, productToChange, newCount) => {
		setUserCarts(prevCarts => {
			const updatedCarts = [...prevCarts];
			const cartIndex = updatedCarts.findIndex(
				cart => cart.storeId === storeId,
			);
			const productToChangeWithId = {
				...productToChange,
				product_with_batch_id: `${productToChange.product_id}+${
					productToChange.batch_id ? productToChange.batch_id : "noBatch"
				}+${productToChange.sku_code ? productToChange.sku_code : "noSku"}`,
			};
			if (cartIndex === -1) {
				// No cart
			} else {
				// Has cart
				const storeCart = { ...updatedCarts[cartIndex] };

				const productIndex = storeCart.products.findIndex(
					product =>
						product.product_with_batch_id ===
						productToChangeWithId.product_with_batch_id,
				);
				if (productIndex === -1) {
					// Has cart - no item
				} else {
					// Has cart - has item
					storeCart.products[productIndex].count = Number(newCount);
					storeCart.products[productIndex].quantity = Number(newCount);
					updatedCarts[cartIndex] = storeCart;
					return updatedCarts;
				}
			}
		});
	};

	const updateProductQuantityInCart = (
		storeId,
		productToChange,
		newQuantity,
	) => {
		setUserCarts(prevCarts => {
			const updatedCarts = [...prevCarts];
			const cartIndex = updatedCarts.findIndex(
				cart => cart.storeId === storeId,
			);
			const productToChangeWithId = {
				...productToChange,
				product_with_batch_id: `${productToChange.product_id}+${
					productToChange.batch_id ? productToChange.batch_id : "noBatch"
				}+${productToChange.sku_code ? productToChange.sku_code : "noSku"}`,
			};
			if (cartIndex === -1) {
				// No cart
			} else {
				// Has cart
				const storeCart = { ...updatedCarts[cartIndex] };

				const productIndex = storeCart.products.findIndex(
					product =>
						product.product_with_batch_id ===
						productToChangeWithId.product_with_batch_id,
				);
				if (productIndex === -1) {
					// Has cart - no item
					// setError("no product in cart");
				} else {
					// Has cart - has item
					storeCart.products[productIndex].quantity = Number(newQuantity);
					updatedCarts[cartIndex] = storeCart;
					return updatedCarts;
				}
			}
		});
	};

	const validateCart = async param => {
		try {
			const res = await api.validateCartProducts({ cartProductsInfo: param });
			if (res.ok) {
				setUserCarts(prevCarts => {
					const updatedCarts = [...prevCarts];
					const cartIndex = updatedCarts.findIndex(
						cart => cart.storeId == Object.keys(param)[0],
					);
					const storeCart = { ...updatedCarts[cartIndex] };
					storeCart.store = res.data.store;
					updatedCarts[cartIndex] = storeCart;
					return updatedCarts;
				});
				updateCartInfo(Object.keys(param)[0], res.data[Object.keys(param)[0]]);
			} else {
				throw res.error;
			}
		} catch (error) {
			console.error(error.message);
		}
	};

	const updateCartInfo = (storeId, newData) => {
		// Check if all quantities in newData are 0
		let shouldRemoveCart = false;
		if (newData?.length) {
			shouldRemoveCart = newData?.every(
				product => product.store_quantity === 0,
			);
		}
		if (!newData || !newData.length || shouldRemoveCart) {
			removeCart(storeId);
			toastMessage("העגלה הוסרה מכיוון שכל המוצרים אזלו מהמלאי", "error");
		} else {
			newData.forEach(product => {
				updatePriceAndQuantity(storeId, product);
			});
		}
	};

	const updatePriceAndQuantity = (storeId, updatedProduct) => {
		const {
			store_price: updatedPrice,
			store_quantity: updatedQuantity,
			product_id: updatedPriductId,
			batch_id: updatedProductBatchId,
		} = updatedProduct;

		if (updatedQuantity === 0) {
			removeProductFromCart(storeId, {
				id: updatedPriductId,
				batch_id: updatedProductBatchId,
			});
		} else {
			setUserCarts(prevCarts =>
				prevCarts.map(cart => {
					if (cart.storeId == storeId) {
						return {
							...cart,
							products: cart.products.map(product => {
								if (
									product.id == updatedPriductId &&
									product.batch_id == updatedProductBatchId
								) {
									if (
										updatedQuantity !== null &&
										product.count > updatedQuantity
									) {
										toastMessage(
											`שים לב, כמות יח׳ ${product.heb_name} התעדכנה לפי הכמות הזמינה בבית המרקחת ${cart.store.display_name}`,
											"error",
										);
									}
									if (product.store_price !== updatedPrice) {
										toastMessage(
											`שים לב, מחיר של  ${product.heb_name} התעדכן בבית המרקחת ${cart.store.display_name}`,
											"info",
										);
									}
									const newCount = Math.min(
										product.count,
										updatedQuantity ?? product.count,
									);
									return {
										...product,
										store_price: updatedPrice,
										quantity: updatedQuantity,
										count: Number(newCount),
									};
								}

								return product;
							}),
						};
					}

					return cart;
				}),
			);
		}
	};

	const getProductCountInCart = (storeId, product) => {
		const newProductWithId = {
			...product,
			product_with_batch_id: `${product.product.id}+${
				product.batch_id ? product.batch_id : "noBatch"
			}+${product.sku_code ? product.sku_code : "noSku"}`,
		};
		const cartIndex = userCarts.findIndex(
			cart => Number(cart.storeId) === Number(storeId),
		);

		if (cartIndex === -1) {
			// setError("no cart found");
			return 0;
		} else {
			const productIndex = userCarts[cartIndex].products.findIndex(product => {
				return (
					product.product_with_batch_id ===
					newProductWithId.product_with_batch_id
				);
			});
			if (productIndex === -1) {
				return 0;
			} else {
				return userCarts[cartIndex].products[productIndex].count;
			}
		}
	};

	const getTotalItemsInAllCarts = () => {
		const totalItems = userCarts?.reduce((acc, cart) => {
			return (
				acc +
				cart.products.reduce(
					(productsAcc, product) => productsAcc + product.count,
					0,
				)
			);
		}, 0);
		return totalItems;
	};

	const getTotalItemsInCart = storeId => {
		const cartIndex = userCarts.findIndex(
			cart => String(cart.storeId) === String(storeId),
		);
		if (cartIndex === -1) {
		} else {
			const totalItems = userCarts[cartIndex].products.reduce(
				(acc, product) => {
					return acc + product.count;
				},
				0,
			);
			return totalItems;
		}
	};

	const getDuplicatesItemCountInCart = (storeId, productId) => {
		const cart = userCarts.find(
			cart => Number(cart.storeId) === Number(storeId),
		);
		if (!cart) return 0;
		const count = cart.products
			.filter(product => {
				return product.id === productId;
			})
			.reduce((acc, prod) => {
				return acc + prod.count;
			}, 0);
		return count;
	};

	const getTotalPrice = storeId => {
		const cart = userCarts?.find(
			cart => String(cart.storeId) === String(storeId),
		);
		if (!!cart?.discounts?.length) {
			return cart.discounts[cart.discounts.length - 1].total_new_price;
		} else {
			return cart.products.reduce((acc, product) => {
				return acc + product.store_price * product.count;
			}, 0);
		}
	};

	const getOriginalPrice = storeId => {
		const cart = userCarts?.find(cart => cart.storeId === storeId);
		return cart.products.reduce((acc, product) => {
			return acc + product.store_price * product.count;
		}, 0);
	};

	return {
		userCarts,
		setUserCarts,
		addToCart,
		removeFromCart,
		removeCart,
		updateProductCountInCart,
		updateProductQuantityInCart,
		getProductCountInCart,
		getTotalItemsInAllCarts,
		getTotalItemsInCart,
		getDuplicatesItemCountInCart,
		widgetOrderModal,
		setWidgetOrderModal,
		widgetCartModal,
		setWidgetCartModal,
		//
		getTotalPrice,
		validateCart,
		getOriginalPrice,
	};
};
