import { useIsInIframe } from "components/hooks/useIsInIframe";
import { useServerTable } from "components/hooks/useServerTable";
import Slider from "components/layout/Slider";
import TableLayout from "components/layout/TableLayout";
import TableDescription from "components/tables/TableDescription";
import { WidgetProductsTable } from "components/tables/WidgetProductsTable";
import { useRouter } from "next/router";
import { LAYOUT } from "util/common";

export async function getServerSideProps(context) {
	const { req } = context;

	const meta = {
		title: "מוצרי רפואי | ג'יין - מלאי רפואי, השוואת מחירים, והזמנת אונליין",
		description:
			"רשימת מלאי מתעדכנת בזמן אמת של כל מוצרי  הרפואי שזמינים כרגע לרכישה בבתי המרקחת ברחבי הארץ. ניתן לסנן ולמיין את הטבלה לפי סוג מוצר, מחיר, קטגוריית מינון, אפיון אינדיקה / סאטיבה, שיטת גידול, THC, CBD, ארץ ייצור, ועוד.",
		url: req.url,
	};

	return {
		props: {
			meta: meta,
		},
	};
}

export default function AllProducts({ prependContent }) {
	const router = useRouter();
	const isInIframe = useIsInIframe();
	const isWidget = router.pathname.includes("widget");

	const params = {
		requestPath: `widget/products/all/`,
		infiniteScrolling: true,
		pageSize: 25,
		layoutOptions: [LAYOUT.LIST, LAYOUT.GRID],
	};

	const tbl = useServerTable(params);
	const { title } = router.query;
	const header = "מוצרים";
	const breadcrumbs = [
		{ path: "/products/", text: "ראשי" },
		{ path: "/products/", text: "מוצרים" },
	];

	return (
		<>
			{!isInIframe && !isWidget && <Slider />}
			<TableLayout header={header} breadcrumbs={breadcrumbs}>
				<TableDescription pageName="products" />
				<WidgetProductsTable
					tbl={tbl}
					title={title || "מוצרים"}
					prependContent={prependContent}
					showPriceRange={true}
					showAds={true}
					isMainTable
				/>
			</TableLayout>
		</>
	);
}
