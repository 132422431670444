import { AtSymbolIcon } from "@heroicons/react/outline";
import React from "react";
import { FaWhatsapp } from "react-icons/fa";

const HelpRow = () => {
	return (
		<div className="text-sm flex gap-2 leading-none pt-0.5 -mb-2 w-full items-center justify-start font-light text-gray-500 ">
			<span>צריכים עזרה?</span>
			<div className="flex items-center gap-1">
				<a
					className="flex items-center gap-0.5"
					href="https://wa.me/0584204401"
					target="_blank"
					rel="noreferrer noopener"
				>
					<FaWhatsapp className="w-4 h-4" />
					<span className="underline">בוואטסאפ</span>
				</a>
				<span>או</span>
				<a className="flex items-center gap-0.5" href="mailto:info@jane.co.il">
					<AtSymbolIcon className="w-4 h-4" />
					<span className="underline">באימייל</span>
				</a>
			</div>
		</div>
	);
};

export default HelpRow;
