import React from "react";
import LinkedContent from "./LinkedContent";

const InfoRow = ({
	content,
	label,
	href,
	bizUrl,
	isSingleStore = false,
	showUnknown = false,
}) => {
	if (!content && !showUnknown) {
		return;
	}
	return (
		<>
			<span className="font-semibold">{label}</span>
			<span>
				<LinkedContent
					href={href}
					bizUrl={bizUrl}
					isSingleStore={isSingleStore}
					showUnknown={showUnknown}
				>
					{content}
				</LinkedContent>
			</span>
		</>
	);
};

export default InfoRow;
