import Head from "next/head";
import Script from "next/script";
import React from "react";
import { defaultMeta } from "./defaultMeta";

const SEOHead = ({ pageProps }) => {
	let { meta } = pageProps;
	const isMeta = !!meta && meta.url.includes("meta");
	if (!meta) {
		meta = defaultMeta;
	}
	const defaultImage = "https://cdn.jane.co.il/general/thumbnail-image.png";

	return (
		<Head>
			<meta
				name="viewport"
				content="width=device-width,initial-scale=1,minimum-scale=1,maximum-scale=3,shrink-to-fit=no"
			/>
			{!isMeta && (
				<Script
					id="disability-widget"
					async
					src="https://cdn.enable.co.il/licenses/enable-L155967t2c3n4zyq-1122-37504/init.js"
				></Script>
			)}
			<script
				dangerouslySetInnerHTML={{
					__html: `
						(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
						new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
						j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
						'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
						})(window,document,'script','dataLayer','GTM-M4PKQWZF');
            			`,
				}}
			/>
			<title>Jane | ג׳יין</title>
			<meta name="description" content={meta.description} />
			<meta
				name="viewport"
				content="width=device-width,initial-scale=1,minimum-scale=1,maximum-scale=3,shrink-to-fit=no"
			/>
			<link rel="canonical" href={meta.url} />
			<meta charSet="UTF-8" />
			<meta name="language" content="he" />
			{/* Open Graph Facebook, LinkedIn, Instagram */}
			<meta property="og:title" content={meta.title} />
			<meta property="og:description" content={meta.description} />
			<meta property="og:url" content={meta.url} />
			<meta property="og:image" content={meta?.image ?? defaultImage} />
			<meta property="og:image:width" content="1200" />
			<meta property="og:image:height" content="627" />
			<meta property="og:type" content="website" />
			{/* Twitter */}
			<meta name="twitter:title" content={meta.title} />
			<meta name="twitter:description" content={meta.description} />
			<meta name="twitter:card" content="summary_large_image" />
			<meta name="twitter:image" content={meta?.image ?? defaultImage} />
			{/* JSON-LD Rich Snippet */}
			{!!pageProps.richSnippets && (
				<script
					type="application/ld+json"
					dangerouslySetInnerHTML={{
						__html: JSON.stringify(pageProps.richSnippets),
					}}
				/>
			)}
		</Head>
	);
};

export default SEOHead;
