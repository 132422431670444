import React, { useState } from "react";
import { ModalDialog } from "./ModalDialog";
import { Button } from "components/form/Button";
import { ArrowLeftIcon } from "@heroicons/react/solid";

const JaneRefModal = ({
	cart,
	store,
	openJaneRefModal,
	setOpenJaneRefModal,
	referral = "iframe in cannabiz store",
}) => {
	const handleRedirect = () => {
		if (!cart?.products?.length) return;

		const productIdWithBatchId = product =>
			`${product.product.id}+${
				product.batch_id ? product.batch_id : "noBatch"
			}+${product.sku_code ? product.sku_code : "noSku"}`;

		const queryToSend = cart.products.map(product => ({
			[productIdWithBatchId(product)]: product.count,
		}));

		const queryParams = queryToSend
			.map(item => {
				const key = Object.keys(item)[0];
				const value = item[key];
				return `${encodeURIComponent(`${key}`)}=${encodeURIComponent(value)}`;
			})
			.join("&");
		window.open(
			`/store/${store?.id}?cart=true&referral=${referral}&${queryParams}`,
			"_blank",
		);
		setOpenJaneRefModal(false);
	};

	return (
		<ModalDialog
			isOpen={openJaneRefModal}
			title={`הזמנה חדשה - ${store?.display_name}`}
			showCancelButton={true}
			showSubmitButton={false}
			cancelBtnText="חזרה"
			maxWidth={600}
			onClose={() => {
				setOpenJaneRefModal(false);
			}}
		>
			<div className="flex flex-col justify-center items-center gap-4">
				<img src="/jane-logo.svg" alt="Jane" className="w-32" />
				<div>להשלמת ההזמנה תועברו לאתר ג&apos;יין</div>
				<Button
					primary
					className="w-full gap-1 flex justify-center max-w-[300px]"
					onClick={() => {
						handleRedirect();
					}}
				>
					<span>השלמת הזמנה בג&apos;יין</span>
					<ArrowLeftIcon className="w-3 h-3" />
				</Button>
			</div>
		</ModalDialog>
	);
};

export default JaneRefModal;
