import { CacheContext } from "components/contexts/CacheContext";
import { useContext, useMemo, useState } from "react";
import { getProductFilters } from "util/commonFilters";
import { ServerTable } from "./server-table/ServerTable";
import { ProductsTableHiddenRow } from "components/products/ProductsTableHiddenRow";
import { ProductsTableColumns } from "components/products/ProductsTableColumns";
import { Toggle } from "components/form/Toggle";
import { toastMessage } from "util/common";
import QuickFilters from "./QuickFilters";
import ProductsSortBy from "components/products/ProductsSortBy";

export function WidgetProductsTable({
	tbl,
	isHomeWidget = false,
	overrideFilters = null,
	hideStockButton = false,
	showCustomButtons = true,
	customButtons = null,
	showPriceRange = false,
	prependSortByContent = null,
	customSortByOption = null,
	showAds = false,
	adType = "filter",
	isMainTable = false,
	withSortBy = true,
	...props
}) {
	const cache = useContext(CacheContext);

	const filters = useMemo(() => {
		return overrideFilters || [...getProductFilters(cache)];
	}, [cache, overrideFilters]);

	const columns = ProductsTableColumns(props);

	const getRowHiddenContent = row => {
		return <ProductsTableHiddenRow isWidget={isHomeWidget} row={row} />;
	};

	return (
		<>
			<ServerTable
				tbl={tbl}
				columns={columns}
				filters={filters}
				getRowHiddenContent={getRowHiddenContent}
				searchable
				isQuickfilter
				isHomeWidget={isHomeWidget}
				showPriceRange={showPriceRange}
				prependStoreSortByContent={
					withSortBy && (
						<ProductsSortBy
							tbl={tbl}
							placeholder="סדר לפי"
							className={"self-stretch flex items-stretch h-full"}
							customSortByOption={customSortByOption}
						/>
					)
				}
				customButtons={showCustomButtons && customButtons}
				subContent={<QuickFilters cache={cache} tbl={tbl} />}
				prependSortByContent={prependSortByContent}
				showAds={showAds}
				adType={adType}
				isMainTable={isMainTable}
				// naked
				{...props}
			/>
		</>
	);
}
